import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Top MEAN Stack Developers | MEAN Stack Development Services in India',
    metaDescription: 'Outsource MEAN Stack Developer & programmers from India to develop custom web applications, progressing web apps and mobile applications at the lowest possible cost. Hire now!',
    h1: 'Hire Dedicated MEAN Stack Developers',
    h1Subtext1: 'Build progressing web apps and secure back-end using MEAN stack development.',
    h1Subtext2: 'MatteCurve is the best MEAN stack development company in India. We are a JavaScript company and have dedicated MEAN developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on the JavaScript ecosystem. Share your requirements and hire MEAN stack developers within 7 days.',
    aboutSection: {
        title: 'About MEAN Stack',
        subTitle: 'A javascript based framework for developing web applications.',
        paragraph1: "MEAN stack is a compilation of JavaScript-based technologies used to develop dynamic web applications and hybrid mobile apps.  An acronym for the tetra combo of MongoDB, ExpressJS, AngularJs, and Node.js.",
        paragraph2: "MEAN is a full-stack developer that has a broader scope and knowledge of various programming languages and stack-based frameworks. This free and open-source stack offers a quick and organized method for creating rapid prototypes for web-based applications.",
        paragraph3: `To build secure and progressing web applications using the MEAN stack, A developer should be well experienced in technologies like MongoDB, Express (A Node.js Framework), Angular JS, and Node.js. They are all javascript technologies, so a MEAN stack developer should be experienced in javascript with database skills. Hire MEAN stack developers from MatteCurve who are experienced and skilled in developing large scale web applications using MEAN stack.`,
        keyPoints: [
            "MEAN is a full-stack development toolkit used to develop fast and robust web applications.",
            "All four technologies (MongoDB, Express (A Node.js Framework), Angular JS, and Node.js) working together have led to the creation of a contemporary niche for the development process.",
            "MEAN stack has gained popularity because it allows developers to use the same javascript language for frontend development as well as backend development.",
            "MEAN stack is a compilation of JavaScript-based technologies used to develop dynamic web applications and hybrid mobile apps.",
            "MEAN can be used to build robust and maintainable solutions.",
            "MEAN is a user-friendly stack that is the ideal solution for building dynamic websites and applications.",
            "Mean stack helps in building apps with the highest speeds delivered. But, everything can be accessed free of cost & that’s the best part of the Mean Stack.",
        ],
    },
    statsSection: {
        paragraph: "MEAN Stack Framework saves up to 60% of your overall full-stack development cost along with a skilled team of MEAN stack developers. In today’s growing market from start-ups to top companies such as fiverr, Accenture, Sisense, and many more. Hire Dedicated MEAN Stack Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated MEAN Stack Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated MEAN Stack Developers?",
        "Hire dedicated MEAN Stack Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for MEAN Stack Development?",
        paragraph: "Our MEAN Stack Application Development Services are designed to help you expand your digital footprint and is one of the finest stacks since it has a channelized workflow, high-performing, scalable, versatile higher speeds, availability of isomorphic coding & cloud integration, etc.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
